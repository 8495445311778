// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// local imports
import { styles } from './Contract.style';
import firstFormatoBeneficiary from 'images/svg/app-formato-beneficiario-1.svg';
import secondFormatoBeneficiary from 'images/svg/app-formato-beneficiario-2.svg';
import formatoTransfer from 'images/app-formato-transferencias.png';

function Contract(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const column = useMediaQuery('(min-width:900px)');
    const mobileBreakpoint = useMediaQuery('(min-width:250px)');

    const blueBreak = useMediaQuery('(min-width:560px)');

    const mobileStart = useMediaQuery('(max-width:749px)');

    const theme = useTheme();
    const normalDots = useMediaQuery(theme.breakpoints.up(1151));
    const mediumDots = useMediaQuery(theme.breakpoints.between(900, 1151));
    const smallerDots = useMediaQuery(theme.breakpoints.between(750, 900));
    const inBetweenSize = useMediaQuery(theme.breakpoints.between(640, 750));
    const reallySmall = useMediaQuery(theme.breakpoints.between(510, 640));
    const smallest = useMediaQuery(theme.breakpoints.down(510));

    return (
        <div>
            <Grid container className={classes.subGridTitle}>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <p className={classes.title}>CONTRATO</p>
                        <p id="bot" className={classes.title}>
                            DE ADHESIÓN
                        </p>
                    </Box>

                    <Box className={classes.section}>
                        <p className={classes.text}>
                            Contrato de adhesión de emisión y depósito mercantil de fondos de pago electrónico que
                            celebran por una parte Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de
                            Pago Electrónico, en adelante “Cuenca”, representada en este acto por Matin Tamizi y por la
                            otra persona cuyo nombre se precisa en la carátula del presente contrato, en adelante el
                            “cliente”, y conjuntamente con Cuenca, “las partes”, al tenor de las siguientes:
                        </p>
                    </Box>
                </Grid>
            </Grid>

            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Declaraciones</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}>I. DECLARA CUENCA, A TRAVÉS DE SU REPRESENTANTE LEGAL, QUE:</p>

                    <p className={classes.text}>
                        a) Es una Institución de Fondos de Pago Electrónico constituida y organizada conforme a las
                        leyes de los Estados Unidos Mexicanos.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        b) Cuenta con las autorizaciones y facultades necesarias para la celebración del presente
                        Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        c) Su representante legal cuenta con las facultades suficientes y necesarias para celebrar el
                        presente Contrato y para obligar a su representada en sus términos, facultades que a la fecha no
                        le han sido revocadas, modificadas o limitadas en forma alguna.
                    </p>
                    <hr />
                    <p className={classes.text}>d) Su objeto social permite la celebración del presente Contrato.</p>
                    <hr />
                    <p className={classes.text}>
                        e) Para todos los efectos legales a que haya lugar, señala como su domicilio el ubicado en
                        Varsovia número 36, Piso 9, Oficina 910, Colonia Juárez, Cuauhtémoc, C.P. 06600, Ciudad de
                        México, México.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        f) El presente Contrato se encuentra debidamente inscrito en el Registro de Contratos de
                        Adhesión de la CONDUSEF bajo los números siguientes: 15717-458-035582/03-01762-0724.
                    </p>
                    <p className={classes.text}>
                        El Cliente podrá consultar la inscripción del Contrato en las páginas de internet siguientes:{' '}
                        <em>cuenca.com</em> y <em>condusef.gob.mx</em>.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        g) Validará los datos y verificará la autenticidad de los documentos obtenidos de manera digital
                        por parte del Usuario.
                    </p>
                    <hr />
                    <p className={classes.text}>h) Es su voluntad celebrar el presente Contrato.</p>
                    <hr />
                    <br />
                    <br />
                    <br />
                    <br />
                    <p className={classes.clause}>II. DECLARA EL CLIENTE, POR SU PROPIO DERECHO QUE:</p>

                    <p className={classes.text}>
                        a) Es una persona física mayor de edad con plenas capacidades de goce y de ejercicio, los datos
                        asentados en la carátula del presente Contrato, así como los documentos exhibidos para acreditar
                        su identidad, nacionalidad, capacidades e inscripción en el Registro Federal de Contribuyentes,
                        son correctos y auténticos.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        b) El dinero que será abonado a la Cuenta de Fondos de Pago Electrónico es de su propiedad y no
                        de otras personas; su origen proviene del desarrollo de actividades lícitas manifestando que
                        conoce y entiende plenamente la regulación consistente en las operaciones con recursos de
                        procedencia ilícita y sus consecuencias.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        c) Conoce los términos y condiciones y el Aviso de Privacidad de Cuenca y que está de acuerdo en
                        que sus datos personales sean tratados y transmitidos de acuerdo con dicho Aviso de Privacidad
                        que se encuentra disponible en la dirección de internet siguiente: <em>www.cuenca.com</em>.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        d) Cuenca le proporcionó los siguientes datos de la CONDUSEF: (i) Centro de atención telefónica
                        01 (55) 5340-0999 y 01-800-999-8080; (ii) dirección de internet <em>www.condusef.gob.mx</em>; y
                        (iii) correo electrónico: <em>asesoria@condusef.gob.mx</em>.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        e) Cuenca le proporcionó los siguientes datos de la UNE: correo electrónico:{' '}
                        <em>une@cuenca.com</em>
                    </p>
                    <hr />
                    <p className={classes.text}>
                        f) Cuenca le informó al Cliente respecto de la existencia de las siguientes redes sociales
                        administradas por Cuenca: i) En Instagram “Cuenca”, ii) En Facebook “Cuenca MX”, iii) En Twitter{' '}
                        <em>“@cuenca_mx”</em> iv) En TikTok <em>“@cuencamx”</em>, v) En YouTube “Cuenca”.
                    </p>
                    <hr />
                    <p className={classes.text}>g) Es su voluntad celebrar el presente Contrato.</p>
                    <hr />
                    <br />
                    <br />
                    <br />
                    <br />
                    <p className={classes.clause}>III. DECLARAN LAS PARTES QUE:</p>

                    <p className={classes.text}>
                        a) Están conscientes que en términos del tercer párrafo del artículo 11 de la Ley para Regular
                        las Instituciones de Tecnología Financiera, ni el Gobierno Federal ni las entidades de la
                        administración pública paraestatal podrán responsabilizarse o garantizar los recursos del
                        Cliente que sean utilizados en las operaciones materia del presente Contrato, así como tampoco
                        asumir alguna responsabilidad por las obligaciones contraídas por Cuenca o por el Cliente frente
                        a otra persona, en virtud de las operaciones que celebren.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        b) Libres de dolo, error, mala fe o cualquier otro vicio de la voluntad, las Partes reconocen
                        que las declaraciones anteriores son ciertas y convienen en obligarse de conformidad con las
                        siguientes:
                    </p>
                    <hr />
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Cláusulas</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}>
                        PRIMERA.- <span className={classes.subclause}>Definiciones.</span>{' '}
                        <span className={classes.text}>Para efectos del presente Contrato, se entenderá por:</span>
                    </p>
                    <p className={classes.text}>
                        <strong>a) App:</strong> a la aplicación para teléfonos inteligentes disponible para IOS y
                        Android por medio de la cual Cuenca realiza sus operaciones.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>b) Aviso de Privacidad:</strong> se refiere al aviso de privacidad publicado en la
                        dirección electrónica siguiente: <em>cuenca.com</em>
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>c) Aviso de Cargos no Reconocidos:</strong> Al Aviso presentado a Cuenca mediante el
                        envío del formato de reclamación del Cliente a Cuenca, mismo que se adjunta al presente como
                        Anexo F.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>d) CLABE:</strong> al identificador único denominado “Clave Básica Estandarizada”, que
                        se le asigna a la Cuenta de Fondos de Pago Electrónico.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>e) Cliente:</strong> persona cuyo nombre y datos se precisan en la carátula del presente
                        Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>f) Cliente Beneficiario:</strong> al Cliente, quien es el titular de la Cuenta de Fondos
                        de Pago Electrónico indicada en la orden de Transferencia de Fondos o de Transferencia de Fondos
                        de Pago Electrónico a la cual Cuenca deba abonar los Fondos de Pago Electrónico equivalentes al
                        monto indicado en la orden de que se trate.
                    </p>
                    <hr />

                    <p className={classes.text}>
                        <strong>g) Comisión:</strong> a cualquier cargo, independientemente de su denominación o
                        modalidad, diferente al interés, que las Instituciones de Tecnología Financiera cobren a los
                        Usuarios, por las operaciones, incluidos los cargos por el uso o aceptación de Medios de
                        Disposición
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>h) Comprobante de operación:</strong> documento físico o electrónico que acredite la
                        realización de operaciones, como pueden ser las evidencias de depósito, retiro, disposición,
                        pago, consulta de saldo y movimientos, etc., que sea emitido y puesto a disposición por
                        cualquier medio, incluida la Plataforma utilizada por las Instituciones de Tecnología Financiera
                    </p>
                    <hr />

                    <p className={classes.text}>
                        <strong>i) Contrato:</strong> el presente contrato de emisión y depósito mercantil de fondos de
                        pago electrónico.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>j) CONDUSEF:</strong> a la Comisión Nacional para la Protección y Defensa de los
                        Usuarios de Servicios Financieros.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>k) Cuenca:</strong> Cuenca Tecnología Financiera, S.A. De C.V., Institución De Fondos De
                        Pago Electrónico
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>l) Cuenta de Fondos de Pago Electrónico:</strong> a aquella que Cuenca, abra a nombre
                        del Cliente, en la que, entre otros, realiza los registros de (a) los abonos correspondientes a:
                        (i) la cantidad de Fondos de Pago Electrónico que Cuenca emita a favor del Cliente, contra la
                        recepción de una cantidad de dinero, en moneda nacional, o (ii) la cantidad de fondos de pago
                        electrónico objeto de las Transferencias de Fondos de Pago Electrónico de que se trate, así como
                        (b) los cargos que correspondan por: (i) la disposición de dichos Fondos de Pago Electrónico, o
                        (ii) la cantidad de Fondos de Pago Electrónico objeto de las Transferencias de Fondos de Pago
                        Electrónico.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>m) Día Hábil Bancario:</strong> a los días en que las entidades financieras no estén
                        obligadas a cerrar sus puertas ni a suspender operaciones, en términos de las disposiciones de
                        carácter general que, para tal efecto, emita la Comisión Nacional Bancaria y de Valores.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>n) Domiciliación:</strong> a la ejecución de Transferencias de Fondos o Transferencias
                        de Fondos de Pago Electrónico, sean individuales o recurrentes, con cargo a una Cuenta de Fondos
                        de Pago Electrónico, que realice Cuenca de acuerdo con la aceptación expresa que el Cliente,
                        titular de dicha cuenta, presente directamente o por medio de un Tercero Autorizado por este.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>o) Entidad Financiera:</strong> a las sociedades controladoras y subcontroladoras de
                        grupos financieros, instituciones de crédito, instituciones de tecnología financiera, casas de
                        bolsa, bolsas de valores, sociedades operadoras de fondos de inversión, sociedades
                        distribuidoras de acciones de fondos de inversión, uniones de crédito, organizaciones auxiliares
                        del crédito, casas de cambio, sociedades financieras de objeto múltiple, sociedades financieras
                        populares, sociedades financieras comunitarias con niveles de operaciones I a IV, organismos de
                        integración financiera rural, sociedades cooperativas de ahorro y préstamo con niveles de
                        operación I a IV, instituciones para el depósito de valores, contrapartes centrales de valores,
                        instituciones calificadoras de valores, sociedades de información crediticia, instituciones de
                        seguros, instituciones de fianzas, sociedades mutualistas de seguros, administradoras de fondos
                        para el retiro, así como otras instituciones y fideicomisos públicos que realicen actividades
                        respecto de las cuales la Comisión Nacional Bancaria y de Valores, la Comisión Nacional de
                        Seguros y Fianzas o la Comisión Nacional del Sistema de Ahorro para el Retiro ejerzan facultades
                        de supervisión;
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>p) Estado de Cuenta:</strong> es el documento emitido por las Instituciones de
                        Tecnología Financiera que informa sobre el estado que guardan las operaciones y servicios
                        contratados por los Usuarios con las mismas.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>q) Fondos de Pago Electrónico:</strong> aquellos fondos que estén contabilizados en un
                        registro electrónico de cuentas transaccionales que, al efecto lleve una Institución de Fondos
                        de Pago Electrónico y que:
                    </p>
                    <hr />
                    <p className={classes.text}>
                        (i) Queden referidos a un valor monetario equivalente a una cantidad determinada de dinero, en
                        moneda nacional.
                    </p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>
                        (ii) Correspondan a una obligación de pago a cargo de su emisor, por la misma cantidad de dinero
                        a que se refiere el inciso (i) inmediato anterior.
                    </p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>
                        (iii) Sean emitidos contra la recepción de la cantidad de dinero a que se refiere se refiere el
                        inciso (i) anterior, con el propósito de abonar, transferir o retirar dichos Fondos de Pago
                        Electrónico, total o parcialmente, mediante la instrucción que, para esos efectos, dé el
                        respectivo tenedor de los Fondos de Pago Electrónico, y
                    </p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>
                        (iv) Sean aceptados por un tercero como recepción de la cantidad de dinero.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>r) Institución de Fondos de Pago Electrónico:</strong> a la entidad financiera
                        autorizada para la prestación de servicios realizados con el público de manera habitual y
                        profesional, consistentes en la emisión, administración, redención y transmisión de fondos de
                        pago electrónico, por medio de los actos que a continuación se señalan, a través de aplicaciones
                        informáticas, interfaces, páginas de internet o cualquier otro medio de comunicación electrónica
                        o digital.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>s) Institución de Tecnología Financiera:</strong> a las instituciones de tecnología
                        financiera reguladas en la Ley, las cuales son las instituciones de financiamiento colectivo y
                        las instituciones de fondos de pago electrónico.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>t) Partes:</strong> conjuntamente Cuenca y el Cliente.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>u) Plataforma de Comunicación:</strong> a la aplicación de mensajería instantánea para
                        teléfonos inteligentes denominada “WhatsApp” por medio de la cual se envían y reciben mensajes
                        cortos y/o mensajes multimedia. Siendo esta la aplicación a través de la cual Cuenca otorga
                        soporte técnico y asistencia, cuyo número oficial de contacto es: 55-13-28-53-64.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>v) RECA:</strong> Registro de Contratos de Adhesión a cargo de CONDUSEF.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>w) Tarjeta:</strong> al medio de disposición de los Fondos de Pago Electrónico
                        registrados en la Cuenta de Fondos de Pago Electrónico de que se trate, constituido como el
                        conjunto de datos que, al procesarse mediante sistemas determinados, permiten iniciar una
                        instrucción de cargo a dicha Cuenta de Fondos de Pago Electrónico, distinta a aquella otra
                        instrucción que se realice para ejecutar una Transferencia de Fondos o una Transferencia de
                        Fondos de Pago Electrónico.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>x) Tercero Autorizado:</strong> con respecto a una Domiciliación, a la persona a la que
                        el titular de la Cuenta de Fondos de Pago Electrónico haya otorgado su autorización para
                        instruir cargos en dicha cuenta.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>y) Términos y Condiciones:</strong> a los términos y condiciones publicados en la
                        dirección electrónica siguiente: <em>cuenca.com</em>, cuyo objeto consiste principalmente en
                        regular la prestación de los servicios objeto del presente Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>z) Transferencias de Fondos:</strong> a aquella operación realizada entre Cuenca y otra
                        Institución de Fondos de Pago Electrónico, Entidad Financiera, Entidad Financiera del exterior o
                        Institución de Fondos de Pago Electrónico del Exterior, conforme a la cual la primera realiza
                        (i) el abono en una Cuenta de Fondos de Pago Electrónico por la cantidad equivalente de dinero a
                        la indicada en la orden respectiva que reciba, derivada del cargo que esa otra Institución de
                        Fondos de Pago Electrónico o Entidad Financiera haga en la cuenta correspondiente, o bien (ii)
                        el cargo en una Cuenta de Fondos de Pago Electrónico equivalente a aquella cantidad de dinero
                        que el Cliente haya indicado en la orden que emita para que, una vez realizada la redención de
                        los referidos Fondos de Pago Electrónico, dicha cantidad se acredite a favor de la otra
                        Institución de Fondos de Pago Electrónico o Entidad Financiera referida a quien se envíe dicha
                        orden para su abono en la cuenta de depósito indicada en dicha orden.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>aa) Transferencias de Fondos de Pago Electrónico:</strong> a la operación realizada por
                        Cuenca de conformidad con este contrato o por cualquier otra Institución de Fondos de Pago
                        Electrónico, de acuerdo con la cual se abona una cantidad determinada de fondos de pago
                        electrónico en una Cuenta, derivado del cargo por la referida cantidad en alguna otra de esas
                        Cuentas.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>bb) UMA:</strong> a la Unidad de Medida y Actualización cuyo valor equivalente en pesos
                        se determina de conformidad con la Ley para Determinar el Valor de la Unidad de Medida y
                        Actualización.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>cc) UNE:</strong> A la Unidad Especializada de Cuenca que tiene por objeto atender las
                        consultas, reclamaciones y aclaraciones de los Usuarios, y
                    </p>
                    <hr />
                    <p className={classes.text}>
                        <strong>dd) Usuario:</strong> A la persona física o moral que contrata o realiza alguna
                        operación con Cuenca.
                    </p>
                    <hr />
                    <br />
                    <br />
                    <p className={classes.clause}>
                        SEGUNDA.- <span className={classes.subclause}>Objeto.</span>{' '}
                        <span className={classes.text}>
                            Las Partes celebran un contrato de adhesión de emisión y depósito mercantil de Fondos de
                            Pago Electrónico asociados a una Cuenta de Fondos de Pago Electrónico en virtud del cual el
                            Cliente podrá efectuar cargos o abonos, en cualquier tiempo, durante la vigencia del
                            presente Contrato. Los límites de los depósitos, saldos y niveles de la Cuenta de Fondos de
                            Pago Electrónico se especifican en los Términos y Condiciones los cuales podrán ser
                            modificados en cualquier momento, de acuerdo con las disposiciones aplicables.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        TERCERA.- <span className={classes.subclause}>Provisión de fondos.</span>{' '}
                        <span className={classes.text}>
                            El Cliente se obliga a realizar la provisión de fondos de la Cuenta de Fondos de Pago
                            Electrónicos, mediante Transferencias de Fondos de Pago Electrónicos de una Cuenta de Fondos
                            de Pago Electrónico abierta en una Institución de Fondos de Pago Electrónico o
                            Transferencias de Fondos de la cuenta señalada por el Cliente, en la carátula del Contrato,
                            abierta en una Entidad Financiera autorizada para recibir depósitos, conforme a la
                            normatividad que resulte aplicable o mediante depósitos en efectivo de conformidad con lo
                            establecido en las disposiciones legales aplicables y los Términos y Condiciones. Una vez
                            realizada la provisión de fondos, Cuenca realizará la correspondiente emisión de Fondos de
                            Pago Electrónico en la Cuenta de Fondos de Pago Electrónico correspondiente.
                            <br />
                            <br />
                            Para el caso de transferencias de fondos provenientes de una cuenta abierta en una Entidad
                            Financiera autorizada para recibir depósitos, conforme a la normatividad que resulte
                            aplicable, los fondos se acreditan de forma inmediata y para los depósitos en efectivo se
                            acreditarán en un lapso de 3 segundos máximo.
                            <br />
                            <br />
                            El Cliente se obliga a realizar la provisión de fondos de la Cuenta de Fondos de Pago
                            Electrónico únicamente hasta por el monto permitido en cada nivel de cuenta correspondiente
                            tal y como lo establecen los Términos y Condiciones, en caso de realizar transacciones
                            superiores al nivel en que se encuentra, Cuenca indicará al Cliente el proceso para
                            incrementar el nivel de cuenta, así como la información y los documentos necesarios para tal
                            efecto.
                            <br />
                            <br />
                            El nivel de la Cuenta de Fondos de Pago Electrónico que Cuenca otorga de forma automática en
                            la contratación es de nivel 1. Al momento de la contratación el Cliente podrá indicar su
                            intención de obtener un nivel más alto quedando Cuenca obligado a darle a conocer al Cliente
                            el proceso de incremento de nivel de Cuenta de Fondos de Pago Electrónico, así como la
                            información y los documentos necesarios.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        CUARTA.- <span className={classes.subclause}>Asignación de cuenta y numero CLABE.</span>{' '}
                        <span className={classes.text}>
                            Una vez que el Cliente concluya con el procedimiento de Registro y cumpla con los requisitos
                            establecidos en los Términos y Condiciones, Cuenca le asignará un numero CLABE a cada
                            Cliente.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        QUINTA.- <span className={classes.subclause}>Intereses.</span>{' '}
                        <span className={classes.text}>
                            Cuenca no pagará al Cliente intereses ni cualquier otro rendimiento o beneficio monetario
                            por el saldo que este acumule en el tiempo o mantenga en un momento dado.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        SEXTA.-{' '}
                        <span className={classes.subclause}>Disposición de los Fondos de Pago Electrónico.</span>{' '}
                        <span className={classes.text}>
                            Cuenca le permitirá al Cliente disponer de los Fondos de Pago Electrónico, así como realizar
                            los cargos correspondientes en la Cuenta de Fondos de Pago Electrónico, mediante:
                        </span>
                    </p>
                    <p className={classes.text}>
                        a) Transferencias de fondos, a las cuentas de depósito de dinero abiertas a nombre del Cliente o
                        de terceros en Entidades Financieras que el propio Cliente especifique al efecto;
                    </p>
                    <hr />
                    <p className={classes.text}>
                        b) Transferencias de Fondos de Pago Electrónico a otras Cuentas de Fondos de Pago Electrónico
                        referidos a la misma moneda;
                    </p>
                    <hr />
                    <p className={classes.text}>
                        c) Pagos de cualquier tipo, mediante el uso de la Tarjeta que Cuenca podrá poner a disposición
                        del Cliente;
                    </p>
                    <hr />
                    <p className={classes.text}>d) Domiciliaciones, y</p>
                    <hr />
                    <p className={classes.text}>e) Retiros de efectivo.</p>
                    <hr />
                    <br />
                    <br />
                    <p className={classes.text}>
                        En caso de que Cuenca efectúe un depósito erróneo en la Cuenta de Fondos de Pago Electrónico del
                        Cliente, el Cliente autoriza expresamente a Cuenca para que, previa comunicación por escrito de
                        Cuenca a través de los medios de notificación establecidos en la cláusula vigésima tercera del
                        Contrato, cargue en la Cuenta de Fondos de Pago Electrónico del Cliente el importe indebidamente
                        abonado por Cuenca.
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        SÉPTIMA.-{' '}
                        <span className={classes.subclause}>
                            Órdenes de Transferencia de Fondos de Pago Electrónicos.
                        </span>{' '}
                        <span className={classes.text}>
                            El Cliente podrá transmitir instrucciones a Cuenca, a través de la App, para la realización
                            de Transferencias de Fondos de Pago Electrónico, mediante el formato de Transferencia de
                            Fondos que Cuenca le proporcione, mismo que se adjunta al presente como Anexo D.
                            <br />
                            <br />
                            Una vez realizada la orden de transferencia y realizado la provisión de fondos
                            correspondiente se efectuará el cargo o abono, según corresponda, en la Cuenta de Fondos de
                            Pago Electrónico del Cliente.
                            <br />
                            <br />
                            Cuenca notificará, por medio de un aviso a través de la App, tanto al Cliente Emisor Cuenca
                            como al Cliente Beneficiario Cuenca, según corresponda, cuando una orden de transferencia de
                            Fondos de Pago Electrónico sea ejecutada.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        OCTAVA.- <span className={classes.subclause}>Cargos no reconocidos.</span>{' '}
                        <span className={classes.text}>
                            Cuando el Cliente envíe a Cuenca un Aviso de Cargo No Reconocido a través de la Plataforma
                            de Comunicación o a través de la dirección de correo: <em>soporte@cuenca.com</em> y en
                            términos de lo dispuesto en la Cláusula Décima Sexta del presente Contrato, Cuenca abonará a
                            más tardar al segundo Día Hábil Bancario posterior a aquel en que haya recibido el aviso
                            respectivo, el monto equivalente al cargo no reconocido, siempre y cuando Cuenca haya
                            recibido el aviso durante un periodo de noventa días naturales posteriores a la fecha en la
                            que se realizó el cargo objeto de la reclamación. Una vez realizado el aviso respectivo, el
                            Cliente deberá seguir el procedimiento establecido en la Cláusulas Décima Sexta, Décima
                            Séptima y Décima Octava del presente Contrato.
                            <br />
                            <br />
                            Cuenca no estará obligada a realizar el abono de recursos antes mencionado cuando compruebe
                            que el Cliente en la operación que haya ocasionado el cargo que se quiere desconocer, haya
                            utilizado, al momento de realizar la operación en cuestión dos elementos de seguridad
                            independientes a los que se refieren las disposiciones aplicables para autenticar las
                            operaciones como autorizadas por el Cliente, o bien, se haya utilizado uno de dichos
                            elementos al momento de realizar la operación y otro de dichos elementos al momento de
                            entregar el bien o servicio adquirido en virtud de la referida operación.
                            <br />
                            <br />
                            El Cliente no será responsable de los cargos a su Cuenta de Fondos de Pago Electrónicos que
                            se efectúen con motivo de la utilización de la Tarjeta a partir de la presentación del aviso
                            referido en la citada Cláusula Décima Sexta, sin embargo, cualquier cargo autorizado de
                            manera previa será responsabilidad del Cliente.
                            <br />
                            <br />
                            Cuenca, en ningún momento podrá requerir al Cliente que realice trámite adicional a la
                            presentación del aviso a que se refiere la presente cláusula para realizar el abono
                            respectivo derivado del cargo no reconocido.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        NOVENA.- <span className={classes.subclause}>Tarjetas.</span>{' '}
                        <span className={classes.text}>
                            Cuenca podrá entregar una Tarjeta, al Cliente que así lo solicite, como un medio de
                            disposición para que el Cliente pueda realizar cargos a su Cuenta de Fondos de Pago
                            Electrónico mediante pagos a establecimientos que acepten dicha Tarjeta. La Tarjeta
                            contendrá la información siguiente: Nombre del Cliente, dígitos de identificación única,
                            fecha de vencimiento y el logo del titular de marca correspondiente.
                            <br />
                            <br />
                            Cuando Cuenca entregue al Cliente una Tarjeta Física para la disposición de Fondos de Pago
                            Electrónico que mantenga en su Cuenta, se la entregará desactivada. Para proceder a la
                            activación de la misma, el Cliente deberá ingresar a la App, ir a la sección “Tus Tarjetas”,
                            seleccionar “Tarjeta Física” y “Activar”, ingresando los datos solicitados dentro de la App.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA.- <span className={classes.subclause}>Estados de Cuenta.</span>{' '}
                        <span className={classes.text}>
                            Las Partes acuerdan que Cuenca envíe un estado de cuenta de forma mensual, a la dirección de
                            correo electrónico indicado en la carátula del Contrato, en donde le permita conocer los
                            cargos y abonos realizados en su Cuenta de Fondos de Pago Electrónico. En el mismo sentido
                            Cuenca tendrá disponible el estado de cuenta de los tres meses inmediatos anteriores en la
                            sesión del Cliente en la App.
                        </span>
                    </p>
                    <p className={classes.text}>Los Estados de Cuenta deberán contener la información siguiente:</p>
                    <p className={classes.text}>a) Saldo inicial y el de la fecha de corte o del final del periodo;</p>
                    <hr />
                    <p className={classes.text}>b) El promedio de los saldos diarios del periodo;</p>
                    <hr />
                    <p className={classes.text}>c) La CLABE;</p>
                    <hr />
                    <p className={classes.text}>
                        d) Beneficios, que en su caso, se hayan generado en el periodo correspondiente, y
                    </p>
                    <hr />
                    <p className={classes.text}>
                        e) Nombre del establecimiento comercial y lugar donde se utilizó la Tarjeta, solo si fuera el
                        caso en que se hubiere utilizado la Tarjeta.
                    </p>
                    <hr />
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA PRIMERA.- <span className={classes.subclause}>Domiciliaciones.</span>{' '}
                        <span className={classes.text}>
                            Cuenca ofrecerá al Cliente el servicio de Domiciliación con cargo a su Cuenta de Fondos de
                            Pago Electrónico. Para la contratación de este servicio el Cliente deberá enviar el formato
                            que se adjunta al presente como Anexo A, a la dirección de correo electrónico:{' '}
                            <em>soporte@cuenca.com</em> o por medio de la Plataforma de Comunicación.
                            <br />
                            <br />
                            El Cliente podrá cancelar el servicio de Domiciliaciones cuando este lo desee enviando a
                            Cuenca la solicitud de cancelación que se adjunta al presente como Anexo B, a la dirección
                            de correo electrónico siguiente: <em>soporte@cuenca.com</em> o por medio de la Plataforma de
                            Comunicación, dicha cancelación surtirá efectos en un plazo no mayor a tres Días Hábiles
                            Bancarios contados a partir de la fecha en que Cuenca reciba la solicitud de cancelación.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA SEGUNDA.- <span className={classes.subclause}>Objeciones de cargos.</span>{' '}
                        <span className={classes.text}>
                            Cuenca atenderá las solicitudes de objeciones de cargo no reconocidos derivados de una
                            domiciliación, mismas que podrán ser presentadas dentro de un plazo de noventa Días Hábiles
                            Bancarios contados a partir de la fecha en que se realizó el cargo respectivo. Dichas
                            objeciones deberán presentarse bajo el mismo formato que el establecido para el Aviso de
                            Cargos No Reconocidos (Anexo F).
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA TERCERA.-{' '}
                        <span className={classes.subclause}>Procedencia de la objeción de cargos.</span>{' '}
                        <span className={classes.text}>
                            Cuando el Cliente objete algún cargo derivado del servicio de Domiciliación durante los
                            primeros sesenta Días Hábiles Bancarios del plazo señalado en la cláusula inmediata
                            anterior, Cuenca deberá abonar el monto reclamado a más tardar el Día Hábil Bancario
                            siguiente a la recepción de la objeción, sin que se requiera del Cliente la realización de
                            trámite adicional alguno.
                            <br />
                            <br />
                            Si la objeción se realiza entre el Día Hábil Bancario sesenta y uno y el Día Hábil Bancario
                            noventa del plazo antes mencionado, Cuenca deberá resolver sobre la procedencia de la
                            reclamación en un plazo máximo de veinte Días Hábiles Bancarios y, en caso de que la
                            objeción resulte procedente, abonar el monto reclamado a más tardar el Día Hábil Bancario
                            siguiente a la fecha de resolución.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA CUARTA.-{' '}
                        <span className={classes.subclause}>Improcedencia de la objeción de cargos.</span>{' '}
                        <span className={classes.text}>
                            En caso de que la objeción de cargos por Domiciliaciones no resulte procedente, Cuenca
                            pondrá a disposición del Cliente la resolución en la que se expresen los argumentos que
                            sustentan la improcedencia, así como copia de los documentos o evidencia de soporte
                            respectivos, incluyendo la proporcionada por el Tercero Autorizado de que se trate.
                            <br />
                            <br />
                            En cualquier caso, Cuenca enviará al Cliente dicha resolución a través del correo
                            electrónico o medio de comunicación que el Cliente señale al presentar la objeción del cargo
                            correspondiente.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA QUINTA.- <span className={classes.subclause}>Comisiones.</span>{' '}
                        <span className={classes.text}>
                            Cuenca únicamente cobrará al cliente las comisiones establecidas en el Anexo E del presente
                            Contrato.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA SEXTA.-{' '}
                        <span className={classes.subclause}>
                            Aviso de robo o extravío de Tarjetas y reclamación de cargos.
                        </span>{' '}
                        <span className={classes.text}>
                            El Cliente podrá presentar, en cualquier día natural: (i) avisos de robo o extravío de la
                            Tarjeta correspondiente, en cuyo caso Cuenca, deberá inhabilitar la Tarjeta, o (ii) avisos
                            de reclamaciones por cargos a su Cuenta de Fondos de Pago Electrónico que no reconozca como
                            propios, a través del formato que como Anexo F al presente Contrato se pone a disposición
                            del Cliente. Para estos efectos los avisos se presentarán por medio de la Plataforma de
                            Comunicación.
                            <br />
                            <br />
                            Una vez que el Cliente haya notificado a Cuenca cualquiera de los avisos a que se refieren
                            el párrafo anterior, Cuenca le proporcionará a través de la Plataforma de Comunicación, un
                            número de referencia, fecha y hora en que se recibió el aviso correspondiente.
                            <br />
                            <br />
                            El Cliente no será responsable de los cargos a su Cuenta de Fondos de Pago Electrónico que
                            se efectúen con motivo de la utilización de la Tarjeta a partir del momento de la
                            presentación del aviso a que se refiere la presente Cláusula, sin embargo, cualquier cargo
                            autorizado de manera previa será responsabilidad del Cliente.
                            <br />
                            <br />
                            Cuenca, en caso de que ocurra cualquier evento previsto en este apartado, bloqueará la
                            Tarjeta del Cliente para evitar que se realicen operaciones sin su consentimiento,
                            adicionalmente, el Cliente podrá bloquear la Tarjeta a través de la App y/o los medios que
                            Cuenca ponga a su disposición.
                            <br />
                            <br />
                            Cuando Cuenca reciba un aviso de reclamación por cargos no reconocidos por un Cliente, ésta
                            deberá abonar, a más tardar el segundo Día Hábil Bancario posterior a aquel en que haya
                            recibido el aviso respectivo, el monto equivalente al cargo no reconocido, siempre y cuando
                            Cuenca haya recibido el aviso durante un periodo de noventa días posteriores a la fecha en
                            la que se realizó el cargo objeto de la reclamación.
                            <br />
                            <br />
                            Cuenca no estará obligada a realizar el abono al que se refiere el párrafo anterior cuando
                            compruebe que el Cliente, al realizar la respectiva operación de Fondos de Pago Electrónico
                            respecto de la cual se hubiere reclamado el cargo no reconocido, hubiere utilizado dos de
                            los elementos independientes a que se refieren las disposiciones aplicables para autenticar
                            las operaciones como autorizadas por el Cliente, o bien, haya utilizado sólo uno de dichos
                            elementos al momento de realizar la referida operación y otro de dichos elementos al momento
                            de entregar el bien o servicio adquirido en virtud de dicha operación. Cuenca en ningún caso
                            podrá requerir al Cliente que realice un trámite adicional a la presentación del aviso a que
                            se refiere el párrafo anterior para realizar los abonos respectivos.
                            <br />
                            <br />
                            Cuenca no requerirá la realización de trámites adicionales a los establecidos en la presente
                            cláusula para la presentación de dichos avisos.
                            <br />
                            <br />
                            Cuenca proporcionará al Cliente, en el momento en que presente el aviso correspondiente, un
                            número de referencia del aviso, la fecha y hora en que este se recibió.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA SÉPTIMA.-{' '}
                        <span className={classes.subclause}>
                            Abonos realizados a Cuentas de Fondos de Pago Electrónico por aviso de robo o extravío de
                            Tarjetas u objeción de cargos.
                        </span>{' '}
                        <span className={classes.text}>
                            Cuenca abonará a la Cuenta de Fondos de Pago electrónico del Cliente correspondiente, a más
                            tardar el segundo Día Hábil Bancario contado a partir del día en que se haya presentado el
                            aviso correspondiente, el monto equivalente a los cargos reclamados siempre y cuando sea
                            procedente.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA OCTAVA.-{' '}
                        <span className={classes.subclause}>
                            Procedencia de los abonos realizados a Cuentas de Fondos de Pago Electrónico por aviso de
                            robo o extravío de Tarjetas u objeción de cargos.
                        </span>{' '}
                        <span className={classes.text}>
                            Cuenca abonará, a más tardar el segundo Día Hábil Bancario contado a partir del día en que
                            se haya presentado el aviso correspondiente, el monto equivalente a los cargos reclamados,
                            siempre que no compruebe al Cliente mediante el dictamen correspondiente, que los cargos
                            reclamados derivan de operaciones en las que, para su realización, se requirió al Cliente
                            utilizar, al menos, dos elementos independientes para autenticar las operaciones como
                            autorizadas por el mismo.
                        </span>
                    </p>
                    <p className={classes.text}>
                        Los dos elementos deberán pertenecer a alguna de las siguientes categorías: a) Información que
                        Cuenca proporciona al Cliente o permite a este generar, a efecto de que solamente él la conozca,
                        para que la pueda ingresar al sistema Cuenca para iniciar la operación de que se trate, tales
                        como contraseña o número de identificación personal; b)Información contenida, recibida o
                        generada por medios o dispositivos electrónicos que solo posee el Cliente, incluyendo la
                        almacenada en un circuito integrado o chip que sea procesada conforme a los estándares
                        correspondientes, así como la obtenida por dispositivos generadores de contraseñas dinámicas que
                        Cuenca proporcione a su Cliente. Lo anterior, siempre y cuando dicha información sea generada
                        con propiedades que impidan su duplicación o alteración y que sea información dinámica que no
                        pueda ser utilizada en más de una ocasión; y c) Información derivada de características propias
                        del Cliente, como aquellas de carácter biométrico, incluyendo huellas dactilares, geometría de
                        la mano o de la cara, patrones en iris o retina, entre otros.
                    </p>
                    <p className={classes.text}>
                        De conformidad con la disposición 36 de la Circular 12/2018 emitida por el Banco de México, el
                        referido dictamen será puesto a disposición del Cliente, a través de la dirección de correo
                        electrónico o medio de comunicación que el Cliente señale al presentar el aviso correspondiente,
                        dentro de un plazo de cuarenta y cinco Días Hábiles Bancarios contados a partir de la fecha en
                        que se haya recibido el aviso correspondiente y contendrá la información siguiente: a) evidencia
                        de los elementos de autenticación utilizados, así como la explicación, en que la verificación de
                        estos se realizó de acuerdo con los procedimientos aplicables a dichos factores de
                        autenticación; b) hora en la que se realizó la operación; c) nombre del receptor de pagos en
                        donde se originó la operación; y d) en caso de contar con ella, la dirección física en la que se
                        encuentra el dispositivo en el que se realizó la operación o, en su caso, dirección del
                        protocolo de internet (IP, por sus siglas en inglés) a través de la cual dicho dispositivo haya
                        estado conectado a internet. En el caso de reclamaciones relativas a operaciones realizados en
                        el extranjero, el plazo previsto en este párrafo será hasta de ciento ochenta días naturales.
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        DÉCIMA NOVENA.-{' '}
                        <span className={classes.subclause}>
                            Reversión de abonos realizados a la Cuenta de Fondos de Pago Electrónico como consecuencia
                            de aviso de robo o extravío de Tarjetas y reclamación de cargos.
                        </span>{' '}
                        <span className={classes.text}>
                            En caso de que Cuenca compruebe que el cargo sujeto a reclamación o derivado de un aviso de
                            extravío deriva de operaciones en las que, para su realización, se requirió al Cliente
                            utilizar, al menos, dos elementos independientes, Cuenca podrá revertir el abono que hubiere
                            realizado a la Cuenta de Fondos de Pago Electrónico del Cliente.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA.- <span className={classes.subclause}>Designación de Beneficiarios.</span>{' '}
                        <span className={classes.text}>
                            De conformidad al artículo 29 de la vigente Ley para Regular las Instituciones de Tecnología
                            Financiera y sus disposiciones secundarias, para el caso de fallecimiento del Cliente, se
                            pone a disposición dentro de la App para que este pueda designar, en las proporciones que
                            para cada uno de ellos determine, a la(s) persona(s), en lo sucesivo Beneficiario(s), a las
                            que Cuenca entregará el importe correspondiente a los Fondos de Pago Electrónico que el
                            Cliente tenga en su Cuenta. Para tal efecto, el cliente deberá proporcionar de cada
                            Beneficiario la información equivalente a la contenida en el Anexo C. El Cliente se obliga a
                            notificar a los Beneficiarios su respectiva designación.
                            <br />
                            <br />
                            Cuando se acredite fehacientemente a Cuenca el fallecimiento del Cliente, si no hubiere
                            instrucciones en contrario, Cuenca realizará mediante las operaciones correspondientes la
                            entrega del importe del saldo del Cliente en su Cuenta de Fondos de Pago Electrónico, previa
                            verificación de identidad, de quienes el Cliente hubiese designado como Beneficiarios, en el
                            porcentaje estipulado para cada uno de ellos.
                            <br />
                            <br />
                            En todo momento el Cliente podrá sustituir, eliminar y/o modificar a través de la App a las
                            personas que designe como Beneficiarios y/o el porcentaje asignado para cada una de ellos,
                            proporcionando la misma información solicitada para la designación.
                            <br />
                            <br />
                            En caso de que el Cliente no designe Beneficiario(s), el importe correspondiente a los
                            Fondos de Pago Electrónico deberá entregarse conforme a lo estipulado por la legislación
                            civil aplicable.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA PRIMERA.-{' '}
                        <span className={classes.subclause}>Tratamiento de cuentas sin movimientos.</span>{' '}
                        <span className={classes.text}>
                            En términos del articulo 28 de la Ley para Regular las Instituciones de Tecnología
                            Financiera, los saldos de las cuentas que en el transcurso de tres años no hayan tenido
                            movimiento por abonos, redención, transmisión o consulta de saldo, deberán ser abonados en
                            una cuenta global que Cuenca tendrá para esos efectos.
                            <br />
                            <br />
                            Para la realización del abono mencionado, con 90 días de anticipación Cuenca notificará al
                            Cliente en la dirección de correo electrónico indicado en la carátula del presente Contrato.
                            <br />
                            <br />
                            Si el Cliente realiza algún movimiento de manera posterior a la transferencia de su saldo a
                            la cuenta global, Cuenca deberá retirar el saldo del Cliente de la cuenta global para
                            ponerlo a disposición de este último.
                            <br />
                            <br />
                            Una vez abonado el saldo del Cliente a la cuenta global y transcurridos tres años sin
                            movimiento alguno, tratándose de Cuentas de Fondos de Pago Electrónico cuyo importe no
                            exceda de trescientas UMA, prescribirá en a favor del patrimonio de la beneficencia pública
                            o el organismo que se designe en la Ley para Regular las Instituciones de Tecnología
                            Financiera.
                            <br />
                            <br />
                            Tratándose de Cuentas de Fondos de Pago Electrónico cuyo importe exceda de trescientas UMA,
                            una vez abonado el saldo del Cliente a la cuenta global y transcurridos siete años sin
                            movimiento alguno, prescribirá en favor del patrimonio de la beneficencia pública o el
                            organismo que se designe en la Ley para Regular las Instituciones de Tecnología Financiera.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA SEGUNDA.-{' '}
                        <span className={classes.subclause}>Confidencialidad y Protección de Datos Personales.</span>{' '}
                        <span className={classes.text}>
                            El Cliente autoriza a Cuenca a utilizar su información personal para las cuestiones
                            relacionadas con el cumplimiento del presente Contrato y con fines de mercadotecnia,
                            publicidad, telemarketing y de acuerdo con al Aviso de Privacidad publicado en la dirección
                            de internet siguiente: <em>cuenca.com</em>.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA TERCERA.- <span className={classes.subclause}>Rescisión del Contrato.</span>{' '}
                        <span className={classes.text}>
                            En caso de que el Cliente no cumpla con alguna de las obligaciones que deriven de este
                            Contrato, del uso, de la buena fe o de la ley, Cuenca podrá rescindir el presente Contrato
                            sin necesidad de declaración judicial previa y sin responsabilidad alguna a su cargo,
                            exigiendo el pago de los daños y perjuicios que correspondan, y sin perjuicio de las
                            acciones judiciales que, en su caso, ejerza.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA CUARTA.- <span className={classes.subclause}>Modificaciones.</span>{' '}
                        <span className={classes.text}>
                            El presente Contrato podrá ser modificado de tiempo en tiempo, total o parcialmente por
                            Cuenca, siempre que esta última dé cumplimiento a los siguientes requisitos:
                        </span>
                    </p>
                    <p className={classes.text}>
                        (i) Se notificará al Cliente con treinta días naturales de anticipación mediante un mensaje
                        enviado al correo electrónico proporcionado. El mensaje contendrá información relativa a las
                        modificaciones realizadas al Presente Contrato y se le invitará al Cliente a revisar los nuevos
                        Términos y Condiciones que estarán disponibles en la página de internet cuenca.com, o, en su
                        caso, en la App. El Cliente manifestará su consentimiento al aceptar los nuevos términos y
                        condiciones del Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        (ii) Cuenca publicará en el siguiente vínculo <em>cuenca.com</em> un anuncio que contendrá:
                    </p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>a) Nombre del producto o servicio y número de registro ante el RECA;</p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>
                        b) Un resumen de las modificaciones realizadas. Tratándose de modificaciones a las comisiones,
                        tasas, rendimientos o descuentos, deberán señalarse las anteriores y las nuevas;
                    </p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>c) Fecha de entrada en vigor; y</p>
                    <hr className={classes.dottedLine} />
                    <p className={classes.text}>
                        d) Se informará al Cliente sobre el derecho de dar por terminado el presente Contrato. Para tal
                        efecto, el Cliente, dentro de los treinta días naturales posteriores al mencionado aviso, podrá
                        dar por terminado el mismo sin responsabilidad alguna a su cargo o penalización. Lo anterior no
                        tendrá como consecuencia que el Cliente no deba cubrir los adeudos que se generen hasta la
                        terminación efectiva del Contrato.
                    </p>
                    <hr />
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA QUINTA.- <span className={classes.subclause}>Notificaciones.</span>{' '}
                        <span className={classes.text}>
                            Todas las notificaciones de Cuenca al Cliente, relacionadas con el presente Contrato y/o que
                            deriven de la Ley, en donde las cláusulas anteriores no prevea un canal de comunicación
                            específico, podrán realizarse: (a) por medio de la App; (b) a la dirección de correo
                            electrónico cuyos datos fueron asentados por el Cliente en la carátula del presente
                            Contrato, y/o (c) por medio de la Plataforma de Comunicación al número del teléfono celular
                            cuyos datos fueron asentados por el Cliente en la carátula del presente contrato.
                            <br />
                            <br />
                            Todas las notificaciones del Cliente hacia Cuenca, relacionadas con el presente Contrato y/o
                            que deriven de la Ley, en donde las cláusulas anteriores no prevea un canal de comunicación
                            específico, podrán realizarse: a) a la dirección de correo electrónico siguiente:{' '}
                            <em>soporte@cuenca.com</em> y/o b) por medio de la Plataforma de Comunicación.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA SEXTA.- <span className={classes.subclause}>Impuestos.</span>{' '}
                        <span className={classes.text}>
                            Los impuestos que resulten por la ejecución de las operaciones llevadas a cabo con base en
                            el presente Contrato serán asumidos por las Partes, en términos de la legislación fiscal
                            vigente.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA SÉPTIMA.-{' '}
                        <span className={classes.subclause}>
                            Cumplimiento a lo establecido por el Artículo 58 de la vigente Ley para Regular las
                            Instituciones de Tecnología Financiera.
                        </span>{' '}
                        <span className={classes.text}>
                            El Cliente acepta en todo momento apegarse a las políticas y procedimientos establecidos en
                            materia de Prevención de Lavado de Dinero y Financiamiento al Terrorismo así como a
                            coadyuvar con Cuenca en todo momento para dar cumplimiento a las obligaciones aplicables,
                            por lo cual, mientras persista la relación contractual, acepta proporcionar la información
                            y/o documentación que le sea requerida por Cuenca con el objeto de atender cabalmente tanto
                            a lo establecido en la normatividad aplicable en dicha materia, así como a las políticas,
                            procedimientos y demás lineamientos que para tal efecto se encuentren vigentes, razón por la
                            cual, se tiene por consentido que en cualquier momento podrán existir requerimientos de
                            información y/o documentación adicionales a los previamente convenidos.
                            <br />
                            <br />
                            En virtud de lo anterior, Cuenca se reserva el derecho de solicitarle al Cliente cualquier
                            información, dato y/o documento adicional con la intención de determinar, actualizar y/o
                            corroborar el perfil transaccional así como el origen y destino de los fondos involucrados
                            en las operaciones que el Cliente realice en virtud de este contrato.
                            <br />
                            <br />
                            El Cliente acepta a su vez que, sin necesidad de declaración judicial previa y, previo a la
                            solicitud de información adicional y el análisis correspondiente, se podrá a total
                            discreción de Cuenca, restringir de forma temporal o definitiva, la Cuenta de Fondos de Pago
                            Electrónico y/o el acceso a la App, para lo cual Cuenca deberá darle aviso al Cliente y sin
                            que sobre esto recaiga responsabilidad alguna a cargo de Cuenca. Derivado de lo anterior, en
                            el supuesto de que el Cliente se comunique con Cuenca a través de la Plataforma de
                            Comunicación validando su identidad en los términos establecidos en el último párrafo de la
                            Cláusula VIGÉSIMA OCTAVA se llevará a cabo la notificación de la resolución correspondiente.
                            <br />
                            <br />
                            En caso de que Cuenca determine suspender o dar por terminada la relación contractual con
                            base en lo previsto en los párrafos precedentes y el Cliente mantenga saldo en su Cuenta de
                            Fondos de Pago Electrónico, el mencionado remanente podrá ser transferido a la cuenta de
                            depósito que se encuentre abierta en una Institución Bancaria autorizada y que el Cliente
                            haya señalado por escrito y de manera expresa, previo cumplimiento de los requisitos que al
                            efecto se tengan establecidos y de conformidad con la normatividad que les resulte
                            aplicable.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA OCTAVA.- <span className={classes.subclause}>Vigencia y Terminación Anticipada.</span>{' '}
                        <span className={classes.text}>El presente Contrato tendrá una vigencia indefinida.</span>
                    </p>
                    <p className={classes.text}>
                        No obstante lo anterior, el Cliente podrá dar por terminado el presente Contrato en cualquier
                        momento por medio de la Plataforma de Comunicación, sin necesidad de rendir un informe o
                        justificación alguna sobre los motivos de dicho acto. En caso de que se hubieren vinculado
                        Tarjetas como medios de disposición, el Cliente deberá manifestar bajo protesta de decir verdad,
                        que no cuenta con ella o que fue destruida.
                    </p>
                    <br />
                    <p className={classes.text}>
                        Consecuentemente, Cuenca tendrá la obligación de proporcionar al Cliente, a más tardar al cierre
                        del Día Hábil Bancario en el que haya presentado la solicitud, un número de referencia de dicha
                        solicitud de terminación, especificando la hora y fecha en que se recibió.
                    </p>
                    <br />
                    <br />
                    <br />
                    <p className={classes.text}>En el caso previsto en esta cláusula Cuenca deberá:</p>
                    <br />
                    <p className={classes.text}>
                        i) Abstenerse de cobrar al Cliente cualquier tipo de comisión o penalización por la terminación.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        ii) Abstenerse de solicitar al Cliente justificación o motivo de la terminación.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        iii) Cancelar, sin responsabilidad alguna, el cobro de algún producto o servicio asociado desde
                        la fecha de terminación del Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        iv) Abstenerse de condicionar la terminación a cualquier acto no previsto en este Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        v) Entregar al Cliente la información relativa a los saldos de los Fondos de Pago Electrónico en
                        el momento en que estos hayan presentado la solicitud correspondiente.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        vi) Entregar los saldos remanentes en la cuenta de Fondos de Pago Electrónico en la fecha en que
                        se den por terminado el Contrato.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        vii) Cancelar la Tarjeta que se vinculó a favor del Cliente como medio de disposición.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        viii) Rechazar cualquier disposición de recursos posterior a la fecha en que se canceló la
                        Tarjeta.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        ix) Cancelar el cobro de algún producto o servicio asociado o domiciliado a partir de la fecha
                        de terminación de los Términos de Uso.
                    </p>
                    <hr />
                    <p className={classes.text}>
                        x) Realizar la Transferencia de Fondos respecto de la totalidad del saldo que mantenga el
                        Cliente en su Cuenta de Fondos de Pago Electrónico, a más tardar al cierre del Día Hábil
                        Bancario respecto del cual el Cliente haya hecho la especificación de la cuenta de depósito de
                        dinero a la vista en alguna Entidad Financiera autorizada para recibir dicha transferencia.
                    </p>
                    <hr />
                    <br />
                    <p className={classes.text}>
                        Adicionalmente el Cliente tiene derecho a dar por terminado el presente Contrato dentro de un
                        periodo de diez Días Hábiles Bancarios posteriores a su aceptación. Dicha terminación no
                        generará responsabilidad de ningún tipo al Cliente. Por tal motivo Cuenca, no podrá cobrar
                        Comisión alguna, siempre que el Cliente no haya utilizado u operado los servicios objeto del
                        presente Contrato.
                    </p>
                    <p className={classes.text}>
                        En aras de la seguridad patrimonial del Cliente y que Cuenca pueda tener completa certeza de la
                        identidad de la persona que contacta, Cuenca podrá solicitarle al Cliente que mande un video de
                        él con duración no mayor a 15 segundos, sosteniendo en su mano una identificación oficial
                        vigente, en donde mencione: i) el día en que el Cliente realiza la solicitud respectiva, ii) la
                        mención del nombre completo del Cliente que contacta y iii) un extracto de la solicitud que
                        realiza hacia Cuenca.
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        VIGÉSIMA NOVENA.- <span className={classes.subclause}>Procedimiento de Cancelación.</span>{' '}
                        <span className={classes.text}>
                            El Cliente podrá cancelar en cualquier momento durante la vigencia del presente contrato y
                            sin condición alguna su Cuenta de Fondos de Pago Electrónico, mediante solicitud dirigida a
                            Cuenca por medio de la Plataforma de Comunicación.
                            <br />
                            <br />
                            Una vez realizada la solicitud de cancelación antes señalada, se cancelarán: (i) Los medios
                            de disposición asociados a su cuenta, incluidos la tarjeta electrónica, la tarjeta física y
                            cualquier tipo de transferencia electrónica de fondos; ii) Cualquier domiciliación asociada
                            a su cuenta.
                            <br />
                            <br />
                            El cierre a que se refiere esta Cláusula surtirá efectos al cierre del día hábil en que
                            Cuenca haya recibido la solicitud de cancelación correspondiente. Una vez que surta efectos
                            el cierre referido, Cuenca se abstendrá de realizar cargos en la Cuenta de Fondos de Pago
                            Electrónico de que se trate.
                            <br />
                            <br />A más tardar al cierre del día hábil de la presentación de la solicitud de
                            cancelación, el saldo remanente en la cuenta será transferido a la cuenta que el Cliente
                            haya especificado para esos efectos. Adicionalmente, Cuenca no podrá condicionar el cierre
                            de la Cuenta una vez que el Cliente haya presentado la referida solicitud de cancelación en
                            los términos antes señalados.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        TRIGÉSIMA.- <span className={classes.subclause}>Cesión.</span>{' '}
                        <span className={classes.text}>
                            Las Partes no podrán ceder en todo o en parte los derechos y obligaciones que se derivan por
                            la celebración de este Contrato.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        TRIGÉSIMA PRIMERA.- <span className={classes.subclause}>Encabezados.</span>{' '}
                        <span className={classes.text}>
                            Los encabezados de este Contrato son para realizar una ágil localización y por mera
                            referencia, por lo que no se considerarán para efectos de su interpretación o cumplimiento.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        TRIGÉSIMA SEGUNDA.- <span className={classes.subclause}>Acuerdo Íntegro.</span>{' '}
                        <span className={classes.text}>
                            El presente Contrato, el Aviso de Privacidad, los Términos y Condiciones y sus anexos,
                            constituyen el acuerdo íntegro habido entre las Partes y sustituye cualquier otra
                            negociación, acuerdo o convenio llevado al cabo con anterioridad.
                        </span>
                    </p>
                    <br />
                    <br />
                    <p className={classes.clause}>
                        TRIGÉSIMA TERCERA.- <span className={classes.subclause}>Legislación y Jurisdicción.</span>{' '}
                        <span className={classes.text}>
                            El presente Contrato se regirá e interpretará de conformidad con las leyes federales de
                            México. Todas las controversias que deriven del presente Contrato, o que guarden relación
                            con éste, serán resueltas por los tribunales competentes de la Ciudad de México, para lo
                            cual las partes en este acto renuncian a cualquier otra jurisdicción que pudiera
                            corresponderles por razón de sus domicilios presentes o futuros.
                            <br />
                            <br />
                            Enteradas las Partes del contenido, alcance y fuerza legal del presente Contrato, lo firman
                            por duplicado, en la Ciudad de México, el [*] de [*] de [*], a las [*] horas,
                            correspondiendo un ejemplar junto con sus anexos para Cuenca y uno para el Cliente, el cual
                            se entregará a de manera electrónica vía correo electrónico y/o estará disponible en la
                            sección “Documentos” dentro de la aplicación Cuenca, a la cual el Cliente tienen acceso una
                            vez que este se ha autenticado.
                            <br />
                            <br />
                            Las firmas contenidas en el presente documento, se recaban de firma autógrafa digitalizada y
                            se incorporan al presente contrato, en términos de lo previsto por el artículo 56 de la Ley
                            para Regular las Instituciones de Tecnología Financiera y Disposiciones que de ella emanen
                            aplicables a dicha forma de expresión del consentimiento.
                            <br />
                            <br />
                            Este contrato ha sido inscrito en el Registro de Contratos de Adhesión (conocido como RECA)
                            de la Comisión Nacional para la Defensa de los Usuarios de las Instituciones Financieras
                            (CONDUSEF) con número 15717-458-035582/03-01762-0724, y tanto el contrato como sus
                            modificaciones podrán ser consultadas en cualquier momento a través de la página{' '}
                            <em>http://eportalif.condusef.gob.mx/reca/_index.php</em> a la cual se puede ingresar
                            también a través de la página <em>www.condusef.gob.mx</em>.
                        </span>
                    </p>
                    <Grid container className={classes.subGridSignatures}>
                        <Grid item xs={6}>
                            <p className={classes.clause}>EL CLIENTE</p>
                            <br />
                            <p className="signatureTextborderTop">Firma</p>
                            <br />
                            <p className="signatureTextBottom">Nombre:</p>
                            <br />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.clause}>CUENCA</p>
                            <br />
                            <p className="signatureTextborderTop">Firma</p>
                            <p className="signatureTextMiddle">
                                Nombre: <span className={classes.text}>Matin Tamizi</span>
                            </p>
                            <p className="signatureTextMiddle">
                                Cargo: <span className={classes.text}>Representante legal</span>
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Carátula de cuentas de fondos de pago electrónicos</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableHeaderAccountFace} colSpan={2}>
                                        NOMBRE DEL CLIENTE:
                                        <br />
                                        <br />
                                        TIPO DE OPERACIÓN:{' '}
                                        <span className="light">Cuenta de Fondos de Pago Electrónicos</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.subtitleAccountFace}>MEDIOS DE ABONO</TableCell>
                                    <TableCell className={classes.subtitleAccountFace}>COMISIONES RELEVANTES</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBordersJustRight}>
                                        <ul>
                                            <li className={classes.textAccountFace}>Efectivo</li>
                                            <li className={classes.textAccountFace}>Transferencia Electrónica</li>
                                            <p className={classes.textAccountFace}>CLABE:</p>
                                            <p className={classes.textAccountFace}>Número de Cuenta:</p>
                                        </ul>
                                    </TableCell>
                                    <TableCell className={classes.tableBordersJustLeft}>
                                        <ul>
                                            <li className={classes.textAccountFace}>
                                                Transferencia electrónica: $10.00 cada una a partir de la transferencia
                                                número 16 realizada en un mes calendario hacia una cuenta abierta en una
                                                Entidad Financiera distinta a Cuenca.
                                            </li>
                                            <li className={classes.textAccountFace}>
                                                Manejo de cuenta: $50.00 (43.10 + IVA) mensuales por concepto de manejo
                                                de cuenta.
                                            </li>
                                            <br />
                                            <li className={classes.textAccountFace}>
                                                Para más información, consulte el Anexo E del Contrato de Adhesión.
                                            </li>
                                            <br />
                                        </ul>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.subtitleAccountFace}>MEDIOS DE DISPOSICION</TableCell>
                                    <TableCell className={classes.subtitleAccountFace}>
                                        LUGARES PARA EFECTUAR RETIROS
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBordersJustRight}>
                                        <ul>
                                            <li className={classes.textAccountFace}>Efectivo</li>
                                            <li className={classes.textAccountFace}>Transferencias Electrónicas</li>
                                        </ul>
                                    </TableCell>
                                    <TableCell className={classes.tableBordersJustLeft}>
                                        <ul>
                                            <li className={classes.textAccountFace}>Cajeros automáticos</li>
                                            <li className={classes.textAccountFace}>Comercios afiliados</li>
                                            <br />
                                        </ul>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.textAccountFaceWborder} colSpan={2}>
                                        <p style={{ marginBottom: 0 }}>
                                            <span className={classes.subtitleAccountFaceBorderless}>ADVERTENCIAS:</span>
                                            &nbsp;&nbsp; "Los recursos de los Clientes en las operaciones realizadas con
                                            Cuenca no se encuentran garantizados por ninguna autoridad" y "Los fondos de
                                            pago electrónico no generan rendimientos o beneficios monetarios por los
                                            saldos acumulados en los mismos".
                                        </p>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.textAccountFaceWborder} colSpan={2}>
                                        <span className={classes.subtitleAccountFaceBorderless}>ESTADO DE CUENTA:</span>
                                        <p>Periocidad: Mensual</p>
                                        <p style={{ marginBottom: 0 }}>
                                            Disponible: Correo electrónico&nbsp;&nbsp;&nbsp;&nbsp; Plataforma
                                        </p>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.textAccountFaceWborder} colSpan={2}>
                                        <span className={classes.subtitleAccountFaceBorderless}>
                                            ACLARACIONES Y RECLAMACIONES:
                                        </span>
                                        <p style={{ marginBottom: 0 }}>
                                            Unidad Especializada de Atención a Usuarios:
                                            <br />
                                            Correo electrónico: <em>UNE@cuenca.com</em>{' '}
                                        </p>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.textAccountFaceWborder} colSpan={2}>
                                        <span className={classes.subtitleAccountFaceBorderless}>
                                            REGISTRO DE CONTRATOS DE ADHESIÓN NÚM: 15717-458-035582/03-01762-0724
                                        </span>
                                        <p style={{ marginBottom: 0 }}>
                                            Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios
                                            Financieros (CONDUSEF): Teléfono 01 800 999 8080 y 53400999. Página de
                                            Internet. <em>www.gob.mx/condusef</em>
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Anexo A</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}>
                        FORMATO PARA SOLICITAR LA CONTRATACIÓN DEL SERVICIO DE DOMICILIACIÓN
                    </p>
                    <p className={classes.attachedText}>
                        <span className="signature">
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de 20
                            <span className="under">&emsp;&emsp;</span>.
                        </span>
                        <br />
                        <br />
                        {mobileBreakpoint ? (
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                        ) : (
                            ''
                        )}
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        <strong>
                            Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico
                        </strong>
                        <br />
                        <br />
                        Solicito y autorizo que con base en la información que se indica en esta comunicación se
                        realicen cargos en mi Cuenta de Fondos de Pago Electrónico conforme a lo siguiente:
                        <br />
                        <br />
                        1) Nombre del Tercero Autorizado para instruir cargos en la Cuenta de Fondos de Pago
                        Electrónico:
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        2) Motivo de la Transferencia de Fondos o Transferencia de Fondos de Pago Electrónico:
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        . En su caso, el número de identificación generado por el Tercero Autorizado (dato no
                        obligatorio):{' '}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                        <br />
                        <br />
                        3) Periodicidad del cargo (Facturación) (Ejemplo: semanal, quincenal, mensual, bimestral,
                        semestral, anual, etc.):
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        . o, en su caso, único indicando el día específico en el que se solicita realizar el cargo:{' '}
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        .
                        <br />
                        <br />
                        4) Nombre de la Institución del Cliente Domiciliado:
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        5) Cualquiera de los datos de identificación de la Cuenta de Fondos de Pago Electrónico,
                        siguientes: Número de Tarjeta (16 dígitos):
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        , o Clave Básica Estandarizada (“CLABE”) de la Cuenta de Fondos de Pago Electrónico (18
                        dígitos): <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                        <br />
                        <br />
                        6) Monto máximo fijo del cargo autorizado por periodo de facturación:
                        <br /> $
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        7) Esta autorización es por plazo indeterminado ( ), o vence el
                        <br />
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        Estoy enterado de que en cualquier momento podré solicitar la cancelación de la presente
                        Domiciliación.
                        <br />
                        <br />
                        <span className="sincerely">
                            ATENTAMENTE,
                            <br />
                            <br />
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            <br />
                        </span>
                    </p>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Anexo B</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}>
                        FORMATO PARA SOLICITAR LA CANCELACIÓN DEL SERVICIO DE DOMICILIACIÓN
                    </p>
                    <p className={classes.attachedText}>
                        <span className="signature">
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de 20
                            <span className="under">&emsp;&emsp;</span>.
                        </span>
                        <br />
                        <br />
                        {mobileBreakpoint ? (
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                        ) : (
                            ''
                        )}
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        <strong>
                            Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico
                        </strong>
                        <br />
                        <br />
                        Solicito a esa institución que cancele la domiciliación del cargo siguiente:
                        <br />
                        <br />
                        1. Nombre del Tercero Autorizado para instruir cargos en la Cuenta de Fondos de Pago
                        Electrónico:
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        2. Motivo de la Transferencia de Fondos o Transferencia de Fondos de Pago Electrónico cuya
                        Domiciliación se solicita cancelar:
                        <br />
                        <br />
                        En su caso, el número de identificación generado por el Tercero Autorizado (dato no
                        obligatorio):
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        .
                        <br />
                        <br />
                        3. Cualquiera de los datos de identificación de la Cuenta de Fondos de Pago Electrónico donde se
                        efectúa el cargo, siguientes:
                        <br />
                        <br />
                        Número de Tarjeta (16 dígitos):
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>,
                        <br />o Clave Básica Estandarizada (“CLABE”) de la Cuenta de Fondos de Pago Electrónico (18
                        dígitos):
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>,
                        <br />
                        <br />
                        <br />
                        Estoy enterado de que la cancelación surtirá efectos en un plazo no mayor a 3 Días Hábiles
                        Bancarios contado a partir de la fecha de presentación de esta solicitud.
                        <br />
                        <br />
                        <span className="sincerely">
                            ATENTAMENTE,
                            <br />
                            <br />
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            <br />
                        </span>
                    </p>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Anexo C</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}>INFORMACIÓN REQUERIDA PARA LA DESIGNACIÓN DE BENEFICIARIOS</p>
                    <Box className={classes.formatoBeneficiaryImg}>
                        <img src={firstFormatoBeneficiary} alt="Formato Beneficiario" />
                        <img src={secondFormatoBeneficiary} alt="Formato Beneficiario" />
                    </Box>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Anexo D</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}>FORMATO DE TRANSFERENCIAS.</p>
                    <Box className={classes.formatoTransferImg}>
                        <img src={formatoTransfer} alt="Ejemplo de transferencia" />
                    </Box>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Anexo E</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.clause}></p>
                    <p className={classes.subcomision}>TRANSFERENCIAS</p>
                    <Box className={`${classes.comision} blueComision`}>
                        <Grid item xs={7} className="comisionText">
                            Cuenca a otros bancos
                            {normalDots || inBetweenSize ? '............................' : null}
                            {mediumDots || reallySmall ? '......................' : null}
                            {smallerDots ? '....................' : null}
                            {smallest ? '.......' : null}
                        </Grid>
                        <Grid item xs={5} className={classes.blueContainer}>
                            <Box
                                className={classes.rightNumber}
                                style={{
                                    flexDirection: !blueBreak ? 'column' : 'row',
                                    alignItems: !blueBreak ? 'flex-start' : 'center',
                                }}
                            >
                                <p className="blueSmallText">15</p>
                                <p style={{ marginBottom: '-2px' }} className="endText">
                                    transferencias mensuales gratis
                                </p>
                            </Box>
                        </Grid>
                    </Box>
                    <Box className={classes.comision}>
                        <Box className={classes.comisionFlexStart}>
                            <Grid item xs={7} className="comisionText">
                                A partir de la transferencia número 16
                                {normalDots || inBetweenSize ? '...' : null}
                                {mediumDots || reallySmall ? '.' : null}
                                {smallerDots ? '.' : null}
                                {smallest ? '..............' : null}
                            </Grid>
                            <Grid item xs={5} className={classes.rightNumber}>
                                <p style={{ marginBottom: '-2px' }} className="endLargeText">
                                    Te informamos que en tu cuenta Cuenca puedes realizar cada mes hasta 15
                                    transferencias electrónicas de fondos gratuitas a una entidad financiera distinta a
                                    Cuenca. Una vez superado este límite mensual, cada una de las transferencias
                                    realizadas hacia entidades distintas a Cuenca tendrán una comisión de $8.62 + IVA =
                                    ($10.00).
                                </p>
                            </Grid>
                        </Box>
                    </Box>
                    <p className={classes.subcomision}>OTROS</p>
                    <Box className={classes.comision}>
                        <Grid item xs={7} className="comisionText">
                            Manejo de cuenta
                            {normalDots || inBetweenSize ? '.....................................' : null}
                            {mediumDots || reallySmall ? '........................' : null}
                            {smallerDots ? '......................' : null}
                            {smallest ? '......' : null}
                        </Grid>
                        <Grid item xs={5} className={classes.rightNumber}>
                            <p className="blueSmallText">$50</p>
                            <p style={{ marginBottom: '-2px' }} className="endText">
                                .00 (43.10 + IVA) mensuales por concepto de manejo de cuenta.
                            </p>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses} margin-top="113px">
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Anexo F</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.subcomision}>FORMATO PARA CARGOS NO RECONOCIDOS</p>
                    <p className={classes.attachedText}>
                        <span className="signature">
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de 20
                            <span className="under">&emsp;&emsp;</span>.
                        </span>
                        <br />
                        <br />
                        <span className={classes.subclause}>
                            Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico
                        </span>
                        <br />
                        <br />
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        por mi propio derecho y para efecto de que me sea enviado el Dictamen (respuesta) del presente
                        aviso, señalo como correo electrónico
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        . Bajo protesta de decir verdad expongo lo siguiente:
                        <br />
                        <br />
                        1.- Solicito a esa institución de fondos de pago electrónico la devolución del monto total de $
                        <span className="under">&emsp;&emsp;&emsp;&emsp;</span> por cargos realizados a partir del día
                        <span className="under">&emsp;&emsp;&emsp;</span> de
                        <span className="under">&emsp;&emsp;&emsp;&emsp;</span>de 20
                        <span className="under">&emsp;</span> , a mi Cuenta de Fondos de Pago Electrónico siguiente (16
                        dígitos de la Tarjeta o 18 dígitos de la Clave Básica Estandarizada “CLABE”):
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        .
                        <br />
                        <br />
                        2.- De la Cuenta mencionada, se reportan los siguientes cargos respecto de los cuales solicito
                        una aclaración:
                        <br />
                        <br />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableAllBordersHeaders}>
                                            MOTIVO DE LA RECLAMACIÓN
                                        </TableCell>
                                        <TableCell className={classes.tableAllBordersHeaders}>
                                            FECHA DE TRANSACCIÓN
                                        </TableCell>
                                        <TableCell className={classes.tableAllBordersHeaders}>
                                            NOMBRE DEL NEGOCIO/ RECEPTOR DE PAGOS/CAJERO ATM
                                        </TableCell>
                                        <TableCell className={classes.tableAllBordersHeaders}>IMPORTE</TableCell>
                                        <TableCell className={classes.tableAllBordersHeaders}>TIPO DE MONEDA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(10)].map(() => (
                                        <TableRow>
                                            <TableCell className={classes.tableAllBorders}></TableCell>
                                            <TableCell className={classes.tableAllBorders}></TableCell>
                                            <TableCell className={classes.tableAllBorders}></TableCell>
                                            <TableCell className={classes.tableAllBorders}></TableCell>
                                            <TableCell className={classes.tableAllBorders}></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <br />
                        3.- De los cargos descritos en el hecho anterior manifiesto lo siguiente:
                        <br />
                        <br />
                        <span className="under">&emsp;&emsp;</span> La transacción antes mencionada nunca fue realizada
                        por mí, tengo la tarjeta en mi poder.
                        <br />
                        <span className="under">&emsp;&emsp;</span> La transacción antes mencionada nunca fue realizada
                        por mí, no tengo la tarjeta en mi poder.
                        <br />
                        <span className="under">&emsp;&emsp;</span> El importe del voucher fue alterado.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Monto Alterado, los cargos que he detallado en la
                        carta no corresponden a los vouchers reales que tengo en mi poder.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Solo realicé una compra en ese
                        negocio/establecimiento, mi tarjeta obra en mi poder y desconozco los cargos descritos en el
                        hecho 2 de la presente aclaración.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Mi tarjeta fue cargada dos veces por la misma
                        transacción.
                        <br />
                        <span className="under">&emsp;&emsp;</span> La nota de crédito (devolución de mercancía) de la
                        transacción arriba señalada, no ha sido acreditada a mi cuenta.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Servicios no prestados o mercancía no recibida, el
                        comercio no prestó los servicios y/o no envío la mercancía y no ha realizado el reembolso al
                        cliente.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Pago por otros medios. Al intentar pagar con mi
                        tarjeta, hubo un problema, por lo que tuve que pagar por otro medio, sin embargo, me han cargado
                        los intentos de pago que realice con mi tarjeta.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Transacción en cajero automático (me otorgó solo una
                        parte del importe total solicitado).
                        <br />
                        <span className="under">&emsp;&emsp;</span> Transacción en cajero automático (no me otorgó
                        cantidad alguna del importe total solicitado).
                        <br />
                        <span className="under">&emsp;&emsp;</span> Cargo Periódico o Recurrente cancelado o no
                        solicitado.
                        <br />
                        <span className="under">&emsp;&emsp;</span> El importe del cargo excede el monto por periodo
                        autorizado.
                        <br />
                        <span className="under">&emsp;&emsp;</span> Cualquier otro supuesto:
                        <span className="under">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </span>
                        <br />
                        <br />
                        <br />
                        Estoy enterado de que si se resuelve en mi contra y esa Institución de Fondos de Pago
                        Electrónico pretende cobrar por la gestión, el cobro de la comisión no podrá exceder de: $0.00.
                        (CERO PESOS 00/100 M.N.)
                        <br />
                        <br />
                        <br />
                        <br />
                        <span className="signature">
                            A T E N T A M E N T E,
                            <br />
                            <br />
                            <br />
                            <br />
                            <span className="underSign">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            <br />
                            <span className="signer">
                                NOMBRE Y FIRMA AUTÓGRAFA DEL CLIENTE TITULAR DE LA CUENTA DE FONDOS DE PAGO ELECTRÓNICO
                            </span>
                        </span>
                    </p>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(Contract);
